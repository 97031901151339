<template>
  <modal-layout @close="$emit('close')" :title="$t('confirmation')" :isGray="true">
    <div class="change-tariff-modal">
      <p class="price-title">490 ₸</p>
      <div class="payment-method_item">
        <div class="icon-block grey">
          <icon icon-name="salary"></icon>
        </div>
        <div class="wrap-title center">
          <p class="title">{{ $t('payCard') }}</p>
        </div>
      </div>

      <div class="payment-method_item">
        <div class="icon-block">
          <icon icon-name="logo-halyk"></icon>
        </div>
        <div class="wrap-title">
          <p class="title">{{ $t('insurancePolicy') }}</p>
          <p class="subtitle">{{ $t('halykIC') }}</p>
        </div>
      </div>
      <ul class="list-agreement">
        <li class="item">
          <span class="title">{{ $t('agreementDate')}}</span>
          <span class="info">03.08.2021</span>
        </li>
        <li class="item">
          <span class="title">{{ $t('phoneNumber')}}</span>
          <span class="info">+7 (777) 123-45-67</span>
        </li>
        <li class="item pb-33">
          <span class="title">{{ $t('fullName')}}</span>
          <span class="info">Май Павел Олегович</span>
        </li>
      </ul>
      <div class="label-ios-checkbox-wrap">
        <p class="text">
           <i18n path="agreedChangeTariff" tag="a" for="declaration">
            <a href="" class="link">{{ $t('declaration') }}</a>
            <a href="" class="link">{{ $t('rules') }}</a> 
          </i18n>
        </p>  
         <div class="ios-checkbox-wrap">
           <input v-model="check_radio" class="ios-checkbox" 
                  type="checkbox" 
                  id="'is_active_2'">
           <label class="ios-switch" for="'is_active_2'"></label>
         </div>
      </div>
      <button class="bottom-page-btn primary-btn" @click="openVerify" :disabled="!check_radio">{{$t('pay')}} 490 ₸</button>
    </div>
    
  </modal-layout>
</template>
<script>
  export default {
    name: "ChangeTariffModal",
      data() {
        return {
            check_radio: true,
            info: {
              fullname: '',
              phone_number: '',
              start_date: '',
              bonus_product: null,
              amount: 0,
            },
            data_success:{
                title: this.$i18n.t('tariffChanged_1'),
                subtitle: this.$i18n.t('tariffChanged_2'),
                haveDate: true
            },
            tariff:{
                tariff_name: 'Легкий', 
                price: 490, 
                date: '21.05.22 г.'
            },
        }
    },
    methods:{
      openVerify(){
        this.$emit("close");
        let loader = this.$loading.show();
        this.$router.push({name: 'agreement.verify', 
          params: {
            data_success: this.data_success, 
            tariff: this.tariff 
          } 
        });
        setTimeout(() => loader.hide(), 1000);
      }
    }
  }
</script>
<style lang="scss">
   .change-tariff-modal{
      padding: 0 16px;
      margin-bottom: 24px;
      .price-title{
         font-family: 'Manrope';
         font-weight: 800;
         font-size: 28px;
         line-height: 32px;
         text-align: center;
         color: #000000;
         padding: 25px 0px;
      }
      .label-ios-checkbox-wrap{
         padding-top: 16px;
         display: flex;
         align-items: center;
         padding-bottom: 16px;
         border-bottom: 1px solid #C8C7CC;
         .text{
            font-family: 'Manrope';
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #081221;
            .link{
               color: #2AA65C;
            }
         }
      }
   }
   .payment-method_item{
      background: #FFFFFF;
      box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      padding: 10px 16px;
      display: flex;
      margin-bottom: 24px;
      .icon-block{
         margin-right: 16px;
      }
      .wrap-title{
         .title{
            font-family: 'Manrope';
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #071222;
            margin-bottom: 4px;
         }
         .subtitle{
            font-family: 'Manrope';
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #99A3B3;
         }
         &.center{
          display: flex;
          align-items: center;
         }
      }
   }
</style>