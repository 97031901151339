<template>
	<secondary-page-layout :title="title" :info-link="false" :product="product">
		<div class="agreement-item"  v-if="agreement">
			<div class="head-agreement" v-if="agreement">
				<h1 class="title mrr">
		          <span>
		            Страховая премия
		          </span>
		        </h1>
				<p class="price">{{agreement.premKzt}} ₸</p>
			</div>

			<v-dialog width="300" content-class="alert_change-card"/>

			<div class="info-card">
				<div class="item">
					<div class="title-wrap">
						<div class="icon">
							<icon icon-name="calendar-svg"></icon>
						</div>
						<p>Дата оформления </p>
					</div>
					<p class="info" v-if="agreement">{{ beginDate }}</p>
				</div>
				<div class="item">
					<div class="title-wrap">
						<div class="icon">
							<icon icon-name="calendar-svg"></icon>
						</div>
						<p>Дата завершения</p>
					</div>
					<p class="info" v-if="agreement">{{endDate}} </p>
				</div>
			</div>

			<!-- <div class="navigate-agreement">
				<div class="wrap-items" v-if="agreement">
					<a @click="downloadAgreement" class="link-item">
			            <div class="icon-block">
			              <icon icon-name="download-icon"></icon>
			            </div>
			            <p>{{ $t('Скачать') }}</p>
			        </a>
				</div>
			</div> -->
			<ul class="list-agreement" v-if="agreement">
				<li class="item" v-if="agreement.productName">
		            <span class="title">Программа</span>
		            <span class="info" >{{ agreement.productName }}</span>
		        </li>
		        <li class="item" v-if="agreement.fullName">
		            <span class="title">Страхователь</span>
		            <span class="info">{{ agreement.fullName }} </span>
		        </li>
		        <li class="item" v-if="agreement.insPremium">
		            <span class="title">Внесено</span>
		            <span class="info"> <span style="color: gray">{{ agreement.insPremium }} </span> . {{ agreement.premKzt }} ₸</span>
		        </li>
		        <li class="item" v-if="agreement.insAmount">
		            <span class="title">Страховая сумма</span>
		            <span class="info"> <span style="color: gray">{{ agreement.insAmount }} </span> . {{ agreement.amountKzt }} ₸</span>
		        </li>
		        
		        <li class="item" v-if="agreement.rateUsdKzt">
		            <span class="title">Курс HБ РК</span>
		            <span class="info">{{agreement.rateUsdKzt}} ₸</span>
		        </li>

		        <li class="item" v-if="agreement.duration && agreement.durationUnit">
		            <span class="title">Срок действия</span>
		            <span class="info">{{agreement.duration}} {{ agreement.durationUnit }}</span>
		        </li>
		        	
		        <li class="item" v-if="annualRate">
		            <span class="title">Ставка</span>
		            <span class="info">{{ annualRate }} %</span>
		        </li>

		        <li class="item" v-if="agreement.status">
		            <span class="title">Статус</span>
		            <span class="info">{{ agreement.status }}</span>
		        </li>
			</ul>
		</div>
		<div  class="agreement-item" v-if="emty">
			<div class="head-agreement">
				<p class="price not"> Нет информации о полисе </p>
			</div>
		</div>
	</secondary-page-layout>
</template>
<script>
	import ChangeTariffModel from "@/components/Modals/ChangeTariffModel";
	import UnsubscribeModal from "@/components/Modals/UnsubscribeModal";
	import {mapGetters, mapActions} from "vuex";
	import moment from "moment";
  import ePayPayment from "../../mixin/ePayPayment";
	import { getAnnualRate } from '@/api/info';

	export default {
		name: "AgreementItem",
		data() {
		    return {
		    	emty: false,
		      	product: {},
		      	data_success:{
			        title: this.$i18n.t('paymentCardSuccessfully_1'),
			        subtitle: this.$i18n.t('paymentCardSuccessfully_2'),
			    },
			    agreement: null,
			    type: null,
					annualRate: '',
		    }
		},
    mixins: [ePayPayment],
		computed: {
			...mapGetters({
	         	 authUser: 'authUser',
	          	accessToken: 'accessToken'
	      	}),
	     	apiUrl(){
	       	 	return process.env.VUE_APP_BACKEND_URL
	      	},
	      	title() {
		        if(this.agreement){
		          return this.agreement.productName;
		        }else{
		          return '';
		        }
	      	},
	      	policyId(){
	        	return this.$route.params.agreement || '';
	      	},
	      	beginDate() {
		        if(this.agreement){
		          return moment(this.agreement.beginDate).format("DD.MM.YYYY");
		        }else{
		          return '';
		        }
	      	},
	      	endDate() {
		        if(this.agreement){
		          return moment(this.agreement.endDate).format("DD.MM.YYYY");
		        }else{
		          return '';
		        }
	      	},
		},
		created() {
			//this.setDefaultInfo();
		    //this.getProduct();
		    this.getLife();

		},
		methods: {
			...mapActions({
		      changePhoneNumber: 'itemSubscription/changePhoneNumber',
		      changeHolder: 'itemSubscription/changeHolder',
		      changeInsureds: 'itemSubscription/changeInsureds',
		      changeTariff: 'itemSubscription/changeTariff',
		      changeManId: 'itemSubscription/changeManId',
		      changeItemSub: 'itemSubscription/changeItemSub',
      	}),
      	async downloadAgreement() {
	        let loader = this.$loading.show({});
	        try {
	          await window.axios.get(`policies/download/${this.agreement.id}?key=${this.agreement.key}`)
	          loader.hide()
	          let fileLink = document.createElement('a');

	          fileLink.href = `${this.apiUrl}/storage/${this.agreement.id}.pdf`;

	          fileLink.click();
	        } catch (err) {
	          throw err
	        }
      	},
		async setDefaultInfo(){
			const loader = this.$loading.show();
			const {data} = await window.axios.get(`policies/${this.$route.params.agreement}`);
	      	this.agreement = data;
	      	this.changePhoneNumber(this.agreement.holder.contacts.MOBILE);
	      	this.changeHolder(this.agreement.holder);
	      	this.changeItemSub(this.agreement);
	      	if(this.agreement.insureds){
	      		this.changeInsureds(this.agreement.insureds);
	      	}
	      	console.log('agreement', this.agreement);
	      	loader.hide();
		},
      	async getProduct() {
        	const {data} = await window.axios.get(`/insurance-products/${this.$route.params.id}`);
        	this.product = data;
      	},
      	async getLife(){
      		const loader = this.$loading.show();
      		try {
	          	const {data} = await window.axios.get(`/life/policies/${this.$route.params.id}`)

	          	this.agreement = data;

							// const annualRate = await getAnnualRate(data.publicId);

							this.annualRate = 0;

	          	if(!data){
	          		this.emty = true;
	          	}
	        } catch (err) {
	        	console.log('erroro',err);
	          	throw err
	        }
	        loader.hide();
      	},
		openChangeCard(){
				this.$modal.show('dialog', {
			        title: this.$i18n.t('loyalClientModalTitle'),
			        text:  this.$i18n.t('yourCardDebited'),
			        buttons: [{
			          title: this.$i18n.t('good'),
			          handler: () => {
			          	this.$modal.hide('dialog');
			          	this.changeCard();
			            return null;
			          }
			        },
			        {
			          title: this.$i18n.t('cancel'),
			          handler: () => {
			            this.$modal.hide('dialog')
			            return null;
			          }
			        },
			        ]
			    });
			},
			//  Сменить карту
			async changeCard() {
		    	try{
			    	let loader = this.$loading.show();

		            const { data } = await window.axios.post(`/subscription/auth-change-card`, {
		              policy_id: this.agreement.id
		            })

	            	await this.goToPaymentPage(data, this.authUser)
	            	loader.hide();
				}catch(e){
		    		console.log(e);
		    	}
		    },
			openUnsubscribe(){
				this.$modal.show(UnsubscribeModal,{ policy_id: this.policyId, product_id: this.product.id }, this.$modalConfig);
			},
		}
	}
</script>
<style lang="scss">
	.agreement-item{
		padding: 0 16px;
		.head-agreement{
			padding: 0px 24px;
			margin-bottom: 16px;
			.title{
				font-family: 'Manrope';
				font-weight: 500;
				font-size: 12px;
				line-height: 16px;
				color: #566681;
				margin-bottom: 16px;
				&.mrr{
					margin-top: 10px;
				}
			}
			.price{
				font-family: 'Manrope';
				font-weight: 800;
				font-size: 40px;
				line-height: 48px;
				color: #071222;
				margin-bottom: 0px;
				&.not{
					font-size: 21px;
    				line-height: 22px;
    				text-align: center;
				}
			}
		}


		.info-card{
			background: #EDEDED;
			border-radius: 12px;
			padding: 14px 0px;
			display: flex;
			justify-content: center;
			margin-bottom: 16px;
			.item{
				&:first-child{
					margin-right: 20px;
				}
				&:last-child{
					margin-left: 20px;
				}
				.info{
					color: #071222;
					font-family: 'Manrope';
					font-weight: 700;
					font-size: 12px;
					line-height: 16px;
					text-align: center;
					margin-bottom: 0px;
				}
				.title-wrap{
					display: flex;
					align-items: center;
					margin-bottom: 4px;
					padding: 0px;
					p{
						font-family: 'Manrope';
						font-style: normal;
						font-weight: 500;
						font-size: 12px;
						line-height: 16px;
						color: #566681;
						margin-bottom: 0px;
					}

					.icon{
						width: 16px;
						height: 16px;
						border-radius: 50%;
						margin-right: 4px;
						display: flex;
						justify-content: center;
						align-items: center;
						background: #E1F4EE;
						padding: 2px;
						background: #E6F8F3;
						svg{
							path{
								stroke: #11BB91;
							}
							
						}
						&.red{
							/*background: #F8DFE8;*/
							background: #F8DFE8;
							svg{
								path{
									stroke: #FF005D;
								}
							}
						}
					}
				}
			}
		}
		.navigate-agreement{
      padding: 12px 24px;
			background: #FFFFFF;
			box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.05);
			border-radius: 12px;
			margin-bottom: 30px;

			.wrap-items{
				display: flex;
        flex-wrap: wrap;
				padding-bottom: 12px;
				&:nth-child(2){
					margin-top: 32px;
				}
			}
			.link-item{
				display: flex;
        flex-direction: column;
        align-items: center;
        flex: 0 0 33.333333%;
        margin: 1rem 0;
        .icon-block{
					margin-bottom: 4px;
				}
				p{
					font-family: 'Manrope';
					font-weight: 500;
					font-size: 12px;
					line-height: 16px;
					text-align: center;
					color: #071222;
					margin-bottom: 0px;
				}
			}
			.empty-item{
				display: flex;
    			width: 20%;
			}
		}


	}
</style>
